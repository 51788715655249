<template>
<div id="CustomLevelSetting">  
  <Header/>
  <CommonAlert1     ref="commonAlert1" :title="title" :content="content" :btnnameup="btnNameUp" :onfuncup="onFuncUp"/>
  <CommonAlert2Func ref="commonAlert2Func" :title="title"  :content="content" :btnnameup="btnNameUp" :btnnamedown="btnNameDown" :onfuncup="onFuncUp" :onfuncdown="onFuncDown"/>

  <body class="">
    <!-- header -->
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          自訂等級
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
        </a> 
      </div>
    </nav>      
    <!-- End header -->
    <div class="nicolespacetop"></div>  
    <div class="bg-success-soft  py-4 "> 
      <div class="container "> 
        <h5 class="text-dark text-bold mb-0 ps-2 text-n">每週學習天數</h5>
        <div class="d-flex align-content-center justify-content-between mt-3 mt-md-4 px-2 col-md-9  col-xl-8  mx-auto"> 

          <div class="text-center p-3 rounded-3 w-day-30 green-shadow mx-md-3 mx-xl-4" :class="isThree ? 'bg-success' : 'bg-white'" @click="selectDay(3)"> 
            <div class="daybox ">
              <img src="assets/img/img008.png" class="avatar avatar-30 mt-md-3" v-show="isThree">
            </div>
            <div class="text-mm text-dark my-md-2 " :class="isThree ? 'text-white' : ''"> 三天 </div>
          </div>  

          <div class="text-center p-3 rounded-3 w-day-30 green-shadow mx-md-3 mx-xl-4" :class="isFour ? 'bg-success' : 'bg-white'" @click="selectDay(4)"> 
            <div class="daybox"> 
              <img src="assets/img/img008.png" class="avatar avatar-30 mt-md-3" v-show="isFour">
            </div>
            <div class="text-mm text-dark my-md-2" :class="isFour ? 'text-white' : ''"> 四天 </div>
          </div>

          <div class="text-center p-3 rounded-3 w-day-30 green-shadow mx-md-3 mx-xl-4" :class="isFive ? 'bg-success' : 'bg-white'" @click="selectDay(5)"> 
            <div class="daybox"> 
              <img src="assets/img/img008.png" class="avatar avatar-30 mt-md-3" v-show="isFive">
            </div>
            <div class="text-mm text-dark my-md-2" :class="isFive ? 'text-white' : ''"> 五天 </div>
          </div>

        </div>
      </div>
    </div>
      <div class="container mt-4 mt-xl-5"> 
        <div class="d-flex align-items-end">
          <h5 class="text-dark text-bold mb-0 ps-2 text-n">有興趣的主題</h5>
          <h5 class="text-success text-simbold mb-0 ps-2 text-sm">( 可複選4~6個 )</h5>
        </div>    
        <div class=" mt-4 mt-md-5" v-for="(mode, index) in lesson_mode" :key='mode.lmid' v-if='index%5==0'>

<!-- "assets/img/" + mode.image + ".png" -->
<!-- "assets/img/" + mode[index+1].image + ".png" -->


          <div class="d-flex" v-if="index==0">
            <div class="w-20 text-center" @click="selectMode(index)">
              <img v-real-img="'assets/img/' + mode.image + '.png'" src="assets/img/img040.png" class="avatar avatar-s" 
                v-if="getCheckMode(mode.lmid)">
              <img v-real-img="'assets/img/' + mode.image + '-1.png'" src="assets/img/img040.png" class="avatar avatar-s" 
                v-else>
              <h5 class="text-sm mt-1 text-simbold" :class="getCheckMode(mode.lmid) ? 'text-success' : ''"> {{mode.name}} </h5>
            </div>


            <div class="w-20 text-center" @click="selectMode(index+1)">
              <img v-real-img="'assets/img/' + lesson_mode[index+1].image + '.png'" src="assets/img/img040.png" class="avatar avatar-s" v-if="getCheckMode(lesson_mode[index+1].lmid)">
              <img v-real-img="'assets/img/' + lesson_mode[index+1].image + '-1.png'" src="assets/img/img040.png" class="avatar avatar-s" v-else>
              <h5 class="text-sm mt-1 text-simbold" :class="getCheckMode(lesson_mode[index+1].lmid) ? 'text-success' : ''"> {{lesson_mode[index+1].name}} </h5>
            </div>
            <div class="w-20 text-center" @click="selectMode(index+2)">
              <img v-real-img="'assets/img/' + lesson_mode[index+2].image + '.png'" src="assets/img/img040.png" class="avatar avatar-s" v-if="getCheckMode(lesson_mode[index+2].lmid)">
              <img v-real-img="'assets/img/' + lesson_mode[index+2].image + '-1.png'" src="assets/img/img040.png" class="avatar avatar-s" v-else>
              <h5 class="text-sm mt-1 text-simbold" :class="getCheckMode(lesson_mode[index+2].lmid) ? 'text-success' : ''"> {{lesson_mode[index+2].name}} </h5>
            </div>
            <div class="w-20 text-center" @click="selectMode(index+3)">
              <img v-real-img="'assets/img/' + lesson_mode[index+3].image + '.png'" src="assets/img/img040.png" class="avatar avatar-s" v-if="getCheckMode(lesson_mode[index+3].lmid)">
              <img v-real-img="'assets/img/' + lesson_mode[index+3].image + '-1.png'" src="assets/img/img040.png" class="avatar avatar-s" v-else>
              <h5 class="text-sm mt-1 text-simbold" :class="getCheckMode(lesson_mode[index+3].lmid) ? 'text-success' : ''"> {{lesson_mode[index+3].name}} </h5>
            </div>
            <div class="w-20 text-center" @click="selectMode(index+4)">
              <img v-real-img="'assets/img/' + lesson_mode[index+4].image + '.png'" src="assets/img/img040.png" class="avatar avatar-s" v-if="getCheckMode(lesson_mode[index+4].lmid)">
              <img v-real-img="'assets/img/' + lesson_mode[index+4].image + '-1.png'" src="assets/img/img040.png" class="avatar avatar-s" v-else>
              <h5 class="text-sm mt-1 text-simbold" :class="getCheckMode(lesson_mode[index+4].lmid) ? 'text-success' : ''"> {{lesson_mode[index+4].name}} </h5>
            </div>
          </div>

          <div class="d-flex mt-2 mt-md-3" v-else>
            <div class="w-20 text-center" @click="selectMode(index)">
              <img v-real-img="'assets/img/' + mode.image + '.png'" src="assets/img/img040.png" class="avatar avatar-s" v-if="getCheckMode(mode.lmid)">
              <img v-real-img="'assets/img/' + mode.image + '-1.png'" src="assets/img/img040.png" class="avatar avatar-s" v-else>
              <h5 class="text-sm mt-1 text-simbold" :class="getCheckMode(mode.lmid) ? 'text-success' : ''"> {{mode.name}} </h5>
            </div>
            <div class="w-20 text-center" @click="selectMode(index+1)">
              <img v-real-img="'assets/img/' + lesson_mode[index+1].image + '.png'" src="assets/img/img040.png" class="avatar avatar-s" v-if="getCheckMode(lesson_mode[index+1].lmid)">
              <img v-real-img="'assets/img/' + lesson_mode[index+1].image + '-1.png'" src="assets/img/img040.png" class="avatar avatar-s" v-else>
              <h5 class="text-sm mt-1 text-simbold" :class="getCheckMode(lesson_mode[index+1].lmid) ? 'text-success' : ''"> {{lesson_mode[index+1].name}} </h5>
            </div>
            <div class="w-20 text-center" @click="selectMode(index+2)">
              <img v-real-img="'assets/img/' + lesson_mode[index+2].image + '.png'" src="assets/img/img040.png" class="avatar avatar-s" v-if="getCheckMode(lesson_mode[index+2].lmid)">
              <img v-real-img="'assets/img/' + lesson_mode[index+2].image + '-1.png'" src="assets/img/img040.png" class="avatar avatar-s" v-else>
              <h5 class="text-sm mt-1 text-simbold" :class="getCheckMode(lesson_mode[index+2].lmid) ? 'text-success' : ''"> {{lesson_mode[index+2].name}} </h5>
            </div>
            <div class="w-20 text-center" @click="selectMode(index+3)">
              <img v-real-img="'assets/img/' + lesson_mode[index+3].image + '.png'" src="assets/img/img040.png" class="avatar avatar-s" v-if="getCheckMode(lesson_mode[index+3].lmid)">
              <img v-real-img="'assets/img/' + lesson_mode[index+3].image + '-1.png'" src="assets/img/img040.png" class="avatar avatar-s" v-else>
              <h5 class="text-sm mt-1 text-simbold" :class="getCheckMode(lesson_mode[index+3].lmid) ? 'text-success' : ''"> {{lesson_mode[index+3].name}} </h5>
            </div>
            <div class="w-20 text-center" v-if="index+4 < lesson_mode.length" @click="selectMode(index+4)">
              <img v-real-img="'assets/img/' + lesson_mode[index+4].image + '.png'" src="assets/img/img040.png" class="avatar avatar-s" v-if="getCheckMode(lesson_mode[index+4].lmid)">
              <img v-real-img="'assets/img/' + lesson_mode[index+4].image + '-1.png'" src="assets/img/img040.png" class="avatar avatar-s" v-else>
              <h5 class="text-sm mt-1 text-simbold" :class="getCheckMode(lesson_mode[index+4].lmid) ? 'text-success' : ''"> {{lesson_mode[index+4].name}} </h5>
            </div>

          </div>
        </div>
    </div> 


    <!--按鈕-->
    <div class="container text-center mt-4 mt-md-5">
      <button type="button" class="btn btn-lg  btn-success rounded-pill w-95  text-s mx-auto" @click="submit()">確認送出</button>          
    </div>  
    <!-- footer -->
    <div class="nicolespace"></div>
    <div class="nicolespace"></div>
    <nav class="navbar  navbar-light bg-white z-index-3  fixed-bottom py-1">
        <div class="col-12 px-0">
        <div class="d-flex">
            <div class="col-3 text-center">
              <img src="assets/img/ic_tab_home_unselected.png" class="avatar avatar-nicole-sm2 rounded-0"> 
                <h6 class="mb-0 text-muted small text-simbold">首頁</h6>
            </div>       
            <div class="col-3 text-center">
              <img src="assets/img/ic_tab_dailyessay_unselected.png" class="avatar avatar-nicole-sm2 rounded-0"> 
                <h6 class="mb-0 text-muted small text-simbold">每日學習</h6>
            </div>        
            <div class="col-3 text-center">
              <img src="assets/img/ic_tab_course_unselected.png" class="avatar avatar-nicole-sm2 rounded-0"> 
                <h6 class="mb-0 text-muted small text-simbold">深度學習</h6>
            </div>       
            <div class="col-3 text-center">
              <img src="assets/img/ic_tab_me.png" class="avatar avatar-nicole-sm2 rounded-0"> 
                <h6 class="mb-0 text-muted small text-simbold text-success">我的</h6>
            </div>
        </div>
        </div>
    </nav>
    <!-- End footer -->
  </body>
  <Footer/>
</div>
</template>


<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import CommonAlert1 from '@/components/CommonAlert1.vue'
import CommonAlert2Func from '@/components/CommonAlert2Func.vue'


export default {
    name: 'CustomLevelSetting',
    components: {
        Header,
        Footer,
        CommonAlert1,
        CommonAlert2Func,

    },
    data () {
      return { 
        // api setting
        setDebugMode:false ,
        apiDebug: false,
        debugMode: false,
        apiName: "",
        form: [],
        fun: "",
        act: "",


        // Alert
        title: "",
        content: "",
        btnNameUp: "",
        btnNameDown: "",
        onFuncUp: Function,
        onFuncDown: Function,

        // data
        elid: "",
        user: [],
        lesson_mode: [],
        isCount: 0,
        checkLmid: new Map(),

        // Status
        isThree: true,
        isFour: false,
        isFive: false,


      }
    },
    methods: {
      openLogin(){
        this.$JsBridge.callHandler('openVueLoginView',JSON.stringify({backUrl:"CustomLevelSetting", type:""}), this.openLoginCallback);
      },
      openLoginCallback(){

      },
      setOnBack(){
        this.$JsBridge.callHandler('setOnback', '', this.onBack);
      },
      onBack() {
        if (this.$route.query.back != undefined && this.$route.query.back != "" ) {
          if (this.$route.query.back == "LevelExamComplete") {
            this.$router.push({
              name: this.$route.query.back,
              query:{
                back: "LevelExamIntro",
                huepid: this.$route.query.huepid,
                eplid: this.$route.query.eplid
              }
            });
          } 
          else if (this.$route.query.back == "LevelExamIntro") {
            this.$router.push({
              name: this.$route.query.back,
              query:{
                back: this.$route.query.beforeBack
              }
            });
          } 
          else if (this.$route.query.back == "CustomLevelDescription") {
            this.$router.push({
              name: this.$route.query.back,
              query:{
                elid: this.$route.query.elid,
              }
            });
          } 
          else {
            this.$router.push(this.$route.query.back);
          }
        } else {
          this.$router.push({
            name: "CustomLevelSelect"
          });
        }
      },
      getAPI(){
        this.apiName = "page_custom_level_setting";
        oAC.getAllRecord(oAC.getApiParam(this), {uid:this.$GlobalData.uid}).then(res => {
          if(res && res.user != undefined && res.user[0] != undefined){                        
            this.user = res.user;
          }
          if(res && res.lesson_mode != undefined && res.lesson_mode[0] != undefined){                        
            this.lesson_mode = res.lesson_mode;
          }
          this.defaultUserData();
        }).catch((err)=> {
            this.icon = "";
            oltConsole.log(err);
        });
      },
      defaultUserData(){
        if (this.user != undefined && this.user.length > 0) {
          this.selectDay(this.user[0].learn_num);
        }
        if (this.lesson_mode != undefined && this.lesson_mode.length > 0) {
          for (var i = 0; i < this.lesson_mode.length; i++) {
            if (this.lesson_mode[i].is_select == "Y") {
              this.selectMode(i);
            }
          }
        }
      },
      selectDay(index){
        switch(index){
          case 4:
            this.isThree = false;
            this.isFour = true;
            this.isFive = false;
          break;
          case 5:
            this.isThree = false;
            this.isFour = false;
            this.isFive = true;
          break;
          default:
            this.isThree = true;
            this.isFour = false;
            this.isFive = false;
          break;
        }
        this.$forceUpdate();
      },
      selectMode(index){
        if (this.checkLmid.has(this.lesson_mode[index].lmid)) {
          this.checkLmid.delete(this.lesson_mode[index].lmid);
          this.isCount--;
        } else if (this.isCount > 5) {
          this.title = "選擇超過數量";
          this.content = "選項已超過設定上限，請減少已選項目才可再加選喔！";
          this.btnNameUp = "我知道了";
          this.$refs.commonAlert1.onShow();
          return false;
        } else {
          this.checkLmid.set(this.lesson_mode[index].lmid, this.lesson_mode[index].name);
          this.isCount++;
        }

        this.$forceUpdate();
      },
      getCheckMode(lmid){
        return this.checkLmid.has(lmid);
      },
      submit(){
        this.apiName = "page_custom_level_setting_put/"+this.$GlobalData.uid+"/";
        this.form["learn_num"] = this.isThree ? "3" : this.isFour ? "4" : this.isFive ? "5" : "";
        this.apiName += "?learn_num=" + this.form["learn_num"];

        var array = Array.from(this.checkLmid.keys());
        this.form["lmid"] = array;
        this.apiName += "&lmid=" + this.form["lmid"];

        this.form["elid"] = this.elid;
        this.apiName +=  "&elid=" + this.form["elid"];

        oAC.putRecord(oAC.getApiParam(this), {}).then(res => {
            this.$router.push({
              name : 'DailyLearn'
            });
          }
        ).catch((err)=> {
          oltConsole.log(err);
        });
      },
      changeToWebCallback(){

      }
    },
    created(){  
      if (this.$route.params && this.$route.params.elid != "" && this.$route.params.elid != undefined) {
        this.elid = this.$route.params.elid;
      } else if (this.$route.query && this.$route.query.elid != "" && this.$route.query.elid != undefined) {
        this.elid = this.$route.query.elid;
      }
      if (this.$route.params && this.$route.params.uid != "" && this.$route.params.uid != undefined) {
        this.$GlobalData.uid = this.$route.params.uid;
      } else if (this.$route.query && this.$route.query.uid != "" && this.$route.query.uid != undefined) {
        this.$GlobalData.uid = this.$route.query.uid;
      }
      if (this.$JsBridge.isAndroid) {
        this.setOnBack();
      }
      this.getAPI();
    }

};
</script>

